import React from "react";
import ConstantHelper from "../../Models/Constants";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { SxProps, Theme } from "@mui/material";

interface IDatePickerProps {
  label: string;
  value: Date | null;
  onChange: (newDate: Date) => void;
  type?: "desktop" | "mobile";
  disableRestrictions?: boolean;
  sx?: SxProps<Theme>;
}

const DatePicker = ({
  label,
  value,
  onChange,
  type = "desktop",
  disableRestrictions,
  sx,
}: IDatePickerProps) => {
  const handleChangeDate = (newValue: Dayjs | null) => {
    if (newValue != null) {
      let newValueDate = newValue.toDate();
      let date = new Date(
        newValueDate.getTime() - newValueDate.getTimezoneOffset() * 60000
      );
      onChange(date);
    }
  };

  if (type === "desktop")
    return (
      <DesktopDatePicker
        sx={sx}
        label={label}
        value={dayjs(value)}
        onChange={handleChangeDate}
        minDate={
          disableRestrictions ? undefined : dayjs(ConstantHelper.MaxDayBefore)
        }
        maxDate={
          disableRestrictions ? undefined : dayjs(ConstantHelper.MaxDayAfter)
        }
      />
    );

  return (
    <MobileDatePicker
      sx={sx}
      label={label}
      value={dayjs(value)}
      onChange={handleChangeDate}
      minDate={
        disableRestrictions ? undefined : dayjs(ConstantHelper.MaxDayBefore)
      }
      maxDate={
        disableRestrictions ? undefined : dayjs(ConstantHelper.MaxDayAfter)
      }
    />
  );
};

export default DatePicker;
