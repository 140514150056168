import styled from "@emotion/styled";

export const LoginContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-width: 100vw;
    min-height: 100vh;
`;

export const FieldsContainer = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  padding: 100px 20px 0;
  
  
  & > *:nth-child(3){
    margin-top: 10px;
  }

  button {  
    height: 30px;
    margin-top: 20px;
  }
`;

export const ImageStyled = styled.img`
    height: 100vh;
    width: calc(100vw - 320px);
    margin: 0 auto;
    object-fit: contain;
`;

export const ErrorContainer = styled.div`
    color: red;
    text-align: center;
`;
