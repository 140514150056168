import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {saveAs} from 'file-saver'

dayjs.extend(utc)

export const ApiCreateReport = (userId: string, dateFrom: Date, dateTo: Date) =>
  axios.put(`/api/report/${userId}`, {
    dateFrom: dayjs(dateFrom).utc(true).toISOString(),
    dateTo: dayjs(dateTo).utc(true).toISOString()
  },
    {responseType: 'blob'}).then(resp => {
    // console.log(resp.data)
    // let blob = new Blob([resp.data], {type: resp.headers.contentType})
    saveAs(resp.data, 'report.csv');
  });
