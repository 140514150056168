import styled from '@emotion/styled'

export const Container  = styled.div`
  display: flex;
  & > *.MuiDrawer-root{
    width: 250px;
    flex-shrink: 0; 
    
    &.MuiDrawer-paper{
      width: 250px;
      box-sizing: border-box;
    }
  }
  
  .selected {
    border-bottom: 3px solid #0B1FE7;
  }
`

export const ContentContainer = styled.div`
  height: calc(100vh - 80px);
  padding: 40px 0;
  width: 100%;
  display: flex;
`
