import React, { useEffect, useState } from "react";
import { apiGetExpenses } from "../../../Api/ExpensesApi";
import { Expenses } from "../../../Models/Expenses";
import { Transfer, TransferStatusRus } from "../../../Models/Transfer";
import { Revenue } from "../../../Models/Revenue";
import { apiGetUserOperations } from "../../../Api/UserApi";
import ConstantHelper from "../../../Models/Constants";
import dayjs, { Dayjs } from "dayjs";
import {
  Button,
  Container,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const OperationsListPage = () => {
  // @ts-ignore
  const userLogin =
    // @ts-ignore
    window.Telegram?.WebApp?.initDataUnsafe?.user?.username ?? "";
  // @ts-ignore
  const bgColor = window.Telegram?.WebApp?.backgroundColor ?? "white";
  const [isLoading, setIsLoading] = useState(true);
  const [expenses, setExpenses] = useState<Expenses[]>([]);
  const [transfers, setTransfers] = useState<Transfer[]>([]);
  const [revenues, setRevenues] = useState<Revenue[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    window?.Telegram?.WebApp?.expand();
    apiGetUserOperations(userLogin).then((resp) => {
      setExpenses(
        resp.expenses.filter(
          (x: any) =>
            dayjs(x.transferDate as Date)?.diff(dayjs().utc(), "day") > -1
        ) ?? []
      );
      setTransfers(
        resp.transfers.filter(
          (x: any) =>
            dayjs(x.transferDate as Date)?.diff(dayjs().utc(), "day") > -1
        ) ?? []
      );
      setRevenues(
        resp.revenues.filter(
          (x: any) => dayjs(x.created as Date)?.diff(dayjs().utc(), "day") > -1
        ) ?? []
      );
      setIsLoading(false);
    });
  }, [userLogin]);

  const handleEditExpenses = (expensesId: string) => {
    navigate(`/bot/edit-expenses/${expensesId}`);
  };

  const handleEditRevenue = (revenueId: string) => {
    navigate(`/bot/edit-revenue/${revenueId}`);
  };

  const handleEditTransfer = (transferId: string) => {
    navigate(`/bot/edit-transfer/${transferId}`);
  };

  const handleClose = () => {
    // @ts-ignore
    window.Telegram?.WebApp?.close();
  };
  console.log({ expenses, transfers, revenues });

  if (isLoading) return <div>Loading...</div>;
  if (
    expenses.length === 0 &&
    transfers.length === 0 &&
    revenues.length === 0
  ) {
    return (
      <Container
        sx={{ backgroundColor: bgColor, color: "text.primary", p: "20px" }}
      >
        <Typography variant="h5">Нет операций</Typography>
      </Container>
    );
  }

  return (
    <Container
      sx={{ backgroundColor: bgColor, color: "text.primary", p: "20px" }}
    >
      {expenses.length > 0 && (
        <>
          <Typography variant="h5">Расходы</Typography>
          <List sx={{ width: "100%", maxWidth: 360, backgroundColor: bgColor }}>
            {expenses.map((x) => (
              <>
                <ListItem
                  alignItems="flex-start"
                  sx={{ flexDirection: "column", color: "text.primary" }}
                  onClick={() => handleEditExpenses(x.id)}
                >
                  <ListItemText>
                    <b>Дата создания:</b>{" "}
                    {dayjs(x.transferDate).format("DD.MM.YYYY HH:mm")}
                  </ListItemText>
                  <ListItemText>
                    <b>Дата операции:</b>{" "}
                    {dayjs(x.operationDate).format("DD.MM.YYYY")}
                  </ListItemText>

                  <ListItemText>
                    <b>Проект:</b> {x.business.name} -&gt; {x.project.name}
                  </ListItemText>
                  <ListItemText>
                    <b>Сумма:</b> {ConstantHelper.Formatter.format(x.amount)}
                  </ListItemText>
                </ListItem>
                <Divider
                  component="li"
                  sx={{ borderColor: "palette.divider" }}
                />
              </>
            ))}
          </List>
          <br />
        </>
      )}
      {revenues.length > 0 && (
        <>
          <Typography variant="h5">Приходы</Typography>
          <List sx={{ width: "100%", maxWidth: 360, backgroundColor: bgColor }}>
            {revenues.map((x) => (
              <>
                <ListItem
                  alignItems="flex-start"
                  sx={{ flexDirection: "column", color: "text.primary" }}
                  onClick={() => handleEditRevenue(x.id)}
                >
                  <ListItemText>
                    <b>Дата создания:</b>{" "}
                    {dayjs(x.created).format("DD.MM.YYYY HH:mm")}
                  </ListItemText>
                  <ListItemText>
                    <b>Дата операции: </b>
                    {dayjs(x.date).format("DD.MM.YYYY")}
                  </ListItemText>

                  <ListItemText>
                    <b>Проект: </b> {x.business.name} -&gt; {x.project.name}
                  </ListItemText>
                  <ListItemText>
                    <b>Сумма: </b> {ConstantHelper.Formatter.format(x.amount)}
                  </ListItemText>
                </ListItem>
                <Divider
                  component="li"
                  sx={{ borderColor: "palette.divider" }}
                />
              </>
            ))}
          </List>

          <br />
        </>
      )}
      {transfers.length > 0 && (
        <>
          <Typography variant="h5">Переводы</Typography>
          <List sx={{ width: "100%", maxWidth: 360, backgroundColor: bgColor }}>
            {transfers.map((x) => (
              <>
                <ListItem
                  alignItems="flex-start"
                  sx={{ flexDirection: "column", color: "text.primary" }}
                  onClick={() => handleEditTransfer(x.id)}
                >
                  <ListItemText>
                    <b>Дата создания:</b>{" "}
                    {dayjs(x.transferDate).format("DD.MM.YYYY HH:mm")}
                  </ListItemText>
                  <ListItemText>
                    <b>Дата получения: </b>
                    {x.receivingDate
                      ? dayjs(x.receivingDate).format("DD.MM.YYYY")
                      : ""}
                  </ListItemText>
                  <ListItemText>
                    <b>Статус: </b> {TransferStatusRus[x.status]}
                  </ListItemText>
                  <ListItemText>
                    <b>Отправитель: </b> {x.sender?.name} -&gt;{" "}
                    {x.senderProject?.name}
                  </ListItemText>
                  <ListItemText>
                    <b>Получатель: </b> {x.recipient?.name} -&gt;{" "}
                    {x.recipientProject?.name}
                  </ListItemText>
                  <ListItemText>
                    <b>Сумма: </b> {ConstantHelper.Formatter.format(x.amount)}
                  </ListItemText>
                </ListItem>
                <Divider
                  component="li"
                  sx={{ borderColor: "palette.divider" }}
                />
              </>
            ))}
          </List>
          <br />
        </>
      )}
      <Button variant="outlined" fullWidth onClick={handleClose}>
        Закрыть
      </Button>
    </Container>
  );
};

export default OperationsListPage;
