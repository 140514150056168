import React, { useEffect, useMemo, useState } from 'react'
import { Container } from "./styled";
import { Button } from "@mui/material";
import { CellExpand } from "../../../Components/CellExpand";
import SetRevenueModal from "./SetRevenue";
import Table, { DefaultDateFilters, DefaultStringFilters } from "../../../Components/Table";
import { MRT_ColumnDef } from "material-react-table/dist/MaterialReactTable";
import ConstantHelper from "../../../Models/Constants";
import { Revenue } from "../../../Models/Revenue";
import { apiGetAllRevenues, apiToggleDeleteRevenue } from "../../../Api/RevenueApi";
import { Expenses } from "../../../Models/Expenses";

interface ModalState {
    open: boolean
    editModel?: Revenue
}

const RevenuePage = () => {
    const [revenues, setRevenues] = useState<Revenue[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [modalState, setModalState] = useState<ModalState>({ open: false })

    useEffect(() => loadData(), [])
    const loadData = () => {
        apiGetAllRevenues()
            .then(resp => setRevenues(resp.data ?? []))
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))
    }

    const columns = useMemo<MRT_ColumnDef<Revenue>[]>(() => [
        {
            id: 'created',
            accessorKey: 'created',
            header: 'Дата ввода',
            accessorFn: (row) => new Date(row.created),
            Cell: ({ cell }) => cell.getValue<Date>().toLocaleDateString(),
            size: 150,
            meta: 'data',
        },
        {
            id: 'user',
            accessorKey: 'user',
            header: 'Распорядитель',
            accessorFn: (value: Revenue) => value.user.name,
            size: 200,
            meta: 'string',
        },
        {
            id: 'business',
            accessorKey: 'business',
            header: 'Бизнес',
            accessorFn: (value: Revenue) => value.business.name,
            width: 200,
            meta: 'string',
        },
        {
            id: 'project',
            accessorKey: 'project',
            header: 'Проект',
            accessorFn: (value: Revenue) => value.project.name,
            width: 200,
            meta: 'string',
        },
        {
            id: 'amount',
            accessorKey: 'amount',
            header: 'Сумма',
            Cell: ({ cell }) => ConstantHelper.Formatter.format(cell.getValue<number>()),
            size: 150,
            meta: 'number',
        },
        {
            id: 'date',
            accessorKey: 'date',
            header: 'Дата прихода',
            accessorFn: (row) => new Date(row.date),
            Cell: ({ cell }) => cell.getValue<Date>().toLocaleDateString(),
            size: 150,
            meta: 'data',
        },
        {
            id: 'comment',
            accessorKey: 'comment',
            header: 'Комментарий',
            Cell: ({ cell }) => <CellExpand value={cell.getValue()} width={200}/>,
            size: 200,
            meta: 'string',
        },
    ], [])

    const handleEditCell = (item: Revenue) => {
        setModalState({ open: true, editModel: item })
    }

    const handleToggleDeleteItem = (item: Revenue) => {
        apiToggleDeleteRevenue(item.id).then(() => loadData()).catch(err => console.log(err))
    }

    const formatDataToCsv = (data: Revenue[]) => {
        return data.map(x => ({
            id: x.id,
            created: new Date(x.created).toLocaleDateString(),
            date: new Date(x.date).toLocaleDateString(),
            user: x.user.name,
            business: x.business.name,
            project: x.project.name,
            amount: x.amount,
            comment: x.comment,
            isDeleted: x.isDeleted ? 'Да' : 'Нет',
        }))
    }

    const closeModal = () => {
        setModalState({ open: false })
        loadData()
    }

    if(isLoading) return <div>Loading...</div>

    return <Container>
        <Button
            variant="outlined"
            sx={{ width: '250px' }}
            onClick={() => setModalState({ open: true })}
        >
            Внести приход
        </Button>
        <Table
            columns={columns}
            data={revenues}
            csvDataMapper={formatDataToCsv}
            handleEdit={handleEditCell}
            handleToggleDelete={handleToggleDeleteItem}
            initialState={{ sorting: [{ id: 'created', desc: true }] }}
        />

        {modalState.open && <SetRevenueModal isOpen={modalState.open} model={modalState.editModel} onClose={closeModal}/>}
    </Container>
}

export default RevenuePage
