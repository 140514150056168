import React, { useState } from 'react'
import { useNavigate } from "react-router"
import { ErrorContainer, FieldsContainer, ImageStyled, LoginContainer } from "./styled"
import { Button, TextField } from "@mui/material"
import logo from '../../../Images/devLogo.jpg'
import axios from "axios"
import { apiAuthorize } from "../../../Api/AdminApi"

interface IFields {
    login: string
    password: string
}


const LoginPage = () => {
    const [fields, setFields] = useState<IFields>({ login: '', password: '' })
    const [error, setError] = useState('')
    const navigate = useNavigate()

    const handleChange = (e: any) => {
        const { id, value } = e.target
        setFields(prev => ({ ...prev, [id]: value }))
        setError('')
    }

    const handleLogin = async () => {
        if (!fields.login || !fields.password) {
            setError("Заполните все поля!")
            return
        }
        try {
            let response = await apiAuthorize(fields.login, fields.password)
            let token = response.data
            localStorage.setItem('token', token)
            window.location.href = '/admin'
        } catch (ex) {
            console.log(ex)
            setError("Не правильное сочетание логина/пароля")
        }
    }


    return <LoginContainer>
        <FieldsContainer>
            <h2>Авторизация в админ панель</h2>
            <TextField
                id="login"
                value={fields.login}
                onChange={handleChange}
                placeholder="Логин"
            />
            <TextField
                id="password"
                type="password"
                value={fields.password}
                onChange={handleChange}
                placeholder="Пароль"
            />
            {error && <ErrorContainer>{error}</ErrorContainer>}
            <Button onClick={handleLogin} variant="outlined" size="small">Вход</Button>
        </FieldsContainer>
        {/*<ImageStyled src={logo}/>*/}
    </LoginContainer>

}

export default LoginPage
