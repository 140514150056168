import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface IConfirmationDialog {
  open: boolean;
  handleClose: () => void;
  handleAgree: () => void;
  title: string;
  description: string;
}

export const ConfirmationDialog = ({
  open,
  handleClose,
  handleAgree,
  title,
  description,
}: IConfirmationDialog) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отменить</Button>
        <Button
          onClick={() => {
            handleAgree();
            handleClose();
          }}
          autoFocus
        >
          Подтвердить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
