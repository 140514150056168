import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ContentContainer, HeaderContainer } from "./styled";
import * as yup from "yup";
import { useFormik } from "formik";
import { apiGetUsers } from "../../../../Api/UserApi";
import { User, UserRole } from "../../../../Models/User";
import {
  Transfer,
  TransferStatus,
  TransferStatusRus,
} from "../../../../Models/Transfer";
import {
  apiCreateTransfer,
  apiEditTransfer,
} from "../../../../Api/TransferApi";
import MoneyInput from "../../../../Components/MoneyInput";
import { ProjectWithBusiness } from "../../../../Models/Business";
import { apiGetAllProjectWithBusiness } from "../../../../Api/BusinessApi";
import { MinValidDate } from "../../../../Models/Settings";
import DatePicker from "../../../../Components/DatePicker";

interface SetTransferModalProps {
  isOpen: boolean;
  onClose: () => void;
  transfer?: Transfer;
}

const validationSchema = yup.object({
  sender: yup.string().required("Выберите отправителя"),
  recipient: yup.string().required("Выберите получателя"),
  senderProject: yup.string().required("Выберите проект"),
  recipientProject: yup.string().required("Выберите проект"),
  amount: yup
    .number()
    .min(1, "Сумма должна быть больше 1 ₽")
    .required("Введите сумму")
    .typeError("Пожалуйста введите число"),
  transferDate: yup
    .date()
    .required("Введите дату операции")
    .min(MinValidDate, "Дата должна быть больше 01.01.2000")
    .typeError("Пожалуйста введите дату"),
  comment: yup.string(),
  senderRole: yup.mixed<UserRole>().oneOf(Object.values(UserRole)),
  recipientRole: yup.mixed<UserRole>().oneOf(Object.values(UserRole)),
  status: yup
    .mixed<TransferStatus>()
    .oneOf(Object.values(TransferStatus))
    .nullable(),
});

const SetTransferModal = ({
  onClose,
  isOpen,
  transfer,
}: SetTransferModalProps) => {
  const [managers, setManagers] = useState<User[]>([]);
  const [projects, setProjects] = useState<ProjectWithBusiness[]>([]);
  const formik = useFormik({
    initialValues: {
      sender: transfer?.sender.login ?? "",
      recipient: transfer?.recipient.login ?? "",
      senderProject: transfer?.senderProject.id ?? "",
      recipientProject: transfer?.recipientProject.id ?? "",
      amount: transfer?.amount,
      transferDate: transfer?.transferDate,
      comment: transfer?.comment ?? "",
      senderRole: transfer?.senderRole ?? UserRole.Manager,
      recipientRole: transfer?.recipientRole ?? UserRole.Manager,
      status: transfer?.status,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSend(),
  });

  const headerText = transfer ? "Редактировать трансфер" : "Новый трансфер";
  useEffect(() => {
    Promise.all([
      apiGetUsers()
        .then((resp) =>
          setManagers(resp.data.filter((x: any) => !x.isDeleted) ?? [])
        )
        .catch((err) => console.log(err)),
      apiGetAllProjectWithBusiness()
        .then((res) => setProjects(res.filter((x: any) => !x.isDeleted) ?? []))
        .catch((err) => console.log(err)),
    ]);
  }, []);

  const senderProjects = useMemo<ProjectWithBusiness[]>(() => {
    let sender = managers.find((x) => x.login === formik.values.sender);
    return projects.filter((x) => sender?.projectIds.includes(x.id)) ?? [];
  }, [formik.values.sender, projects, managers]);

  const recipientProjects = useMemo<ProjectWithBusiness[]>(() => {
    let recipient = managers.find((x) => x.login === formik.values.recipient);
    return projects.filter((x) => recipient?.projectIds.includes(x.id)) ?? [];
  }, [formik.values.recipient, projects, managers]);

  const handleSend = () => {
    let promise;
    const values = formik.values;
    if (!values.transferDate) return;

    if (transfer) {
      promise = apiEditTransfer({
        id: transfer.id,
        transferDate: values.transferDate,
        comment: values.comment,
        recipientLogin: values.recipient,
        senderProject: values.senderProject,
        recipientProject: values.recipientProject,
        amount: values.amount ?? 0,
      });
    } else {
      promise = apiCreateTransfer({
        senderLogin: values.sender,
        recipientLogin: values.recipient,
        recipientProject: values.recipientProject,
        senderProject: values.senderProject,
        transferDate: values.transferDate,
        amount: values.amount ?? 0,
        senderRole: values.senderRole,
        recipientRole: values.recipientRole,
        comment: values.comment,
      });
    }
    promise.then(handleClose).catch((err) => console.log(err));
  };

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleChangeSelect = (field: string) => (event: SelectChangeEvent) => {
    const value = event.target.value;
    formik.setFieldValue(field, value);
  };

  const handleChangeDate = (newValue: Date | null) => {
    if (newValue != null) {
      let date = new Date(
        newValue.getTime() - newValue.getTimezoneOffset() * 60000
      );
      formik.setFieldValue("transferDate", date);
    }
  };

  const senders = managers.filter((x) =>
    x.roles.includes(formik.values.senderRole)
  );
  const recipients = managers.filter((x) =>
    x.roles.includes(formik.values.recipientRole)
  );

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ContentContainer>
        <HeaderContainer>
          <h4>{headerText}</h4>
          <Button variant="text" onClick={handleClose}>
            Закрыть
          </Button>
        </HeaderContainer>
        <FormControl
          fullWidth
          disabled={!!transfer}
          error={formik.touched.sender && Boolean(formik.errors.sender)}
        >
          <InputLabel htmlFor="sender">Отправитель</InputLabel>
          <Select
            variant="outlined"
            id="sender"
            value={formik.values.sender}
            label="Отправитель"
            onChange={handleChangeSelect("sender")}
          >
            {senders.map((x) => (
              <MenuItem key={x.id} value={x.login}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formik.touched.sender && formik.errors.sender}
          </FormHelperText>
        </FormControl>

        <FormControl
          fullWidth
          disabled={!formik.values.sender}
          error={
            formik.touched.senderProject && Boolean(formik.errors.senderProject)
          }
        >
          <InputLabel htmlFor="senderProject">Проект списания</InputLabel>
          <Select
            id="senderProject"
            variant="outlined"
            value={formik.values.senderProject ?? ""}
            label="Проект списания"
            onChange={handleChangeSelect("senderProject")}
          >
            {senderProjects.map((x) => (
              <MenuItem key={x.id} value={x.id}>
                {x.business.name} - {x.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formik.touched.senderProject && formik.errors.senderProject}
          </FormHelperText>
        </FormControl>

        <FormControl
          fullWidth
          error={formik.touched.recipient && Boolean(formik.errors.recipient)}
        >
          <InputLabel htmlFor="recipient">Получатель</InputLabel>
          <Select
            variant="outlined"
            id="recipient"
            value={formik.values.recipient}
            label="Получатель"
            onChange={handleChangeSelect("recipient")}
          >
            {recipients.map((x) => (
              <MenuItem key={x.id} value={x.login}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formik.touched.recipient && formik.errors.recipient}
          </FormHelperText>
        </FormControl>

        <FormControl
          fullWidth
          disabled={!formik.values.recipient}
          error={
            formik.touched.recipientProject &&
            Boolean(formik.errors.recipientProject)
          }
        >
          <InputLabel htmlFor="recipientProject">Проект получения</InputLabel>
          <Select
            id="recipientProject"
            variant="outlined"
            value={formik.values.recipientProject ?? ""}
            label="Проект получения"
            onChange={handleChangeSelect("recipientProject")}
          >
            {recipientProjects.map((x) => (
              <MenuItem key={x.id} value={x.id}>
                {x.business.name} - {x.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formik.touched.recipientProject && formik.errors.recipientProject}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <MoneyInput
            id="amount"
            label="Сумма"
            value={formik.values.amount}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₽</InputAdornment>
              ),
            }}
            ChangeCallback={(val: number | undefined) =>
              formik.setFieldValue("amount", val)
            }
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
          />
        </FormControl>
        <FormControl
          error={
            formik.touched.transferDate && Boolean(formik.errors.transferDate)
          }
        >
          <DatePicker
            label="Дата операции"
            value={formik.values.transferDate ?? null}
            onChange={(date) => formik.setFieldValue("transferDate", date)}
          />
          <FormHelperText>
            {formik.touched.transferDate && formik.errors.transferDate}
          </FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            id="comment"
            label="Комментарий"
            value={formik.values.comment}
            onChange={formik.handleChange}
            multiline
            rows={4}
            variant="outlined"
          />
        </FormControl>

        {transfer && (
          <FormControl
            fullWidth
            disabled={transfer?.status !== TransferStatus.Created}
          >
            <InputLabel htmlFor="status">Статус трансфера</InputLabel>
            <Select
              variant="outlined"
              id="status"
              value={formik.values.status}
              label="Получатель"
              onChange={handleChangeSelect("status")}
            >
              <MenuItem value={TransferStatus.Created}>
                {TransferStatusRus[TransferStatus.Created]}
              </MenuItem>
              <MenuItem value={TransferStatus.Confirmed}>
                {TransferStatusRus[TransferStatus.Confirmed]}
              </MenuItem>
              <MenuItem value={TransferStatus.Rejected}>
                {TransferStatusRus[TransferStatus.Rejected]}
              </MenuItem>
            </Select>
          </FormControl>
        )}

        <Button variant="outlined" onClick={() => formik.handleSubmit()}>
          Сохранить
        </Button>
      </ContentContainer>
    </Modal>
  );
};

export default SetTransferModal;
