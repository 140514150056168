import React, { useEffect, useState } from 'react'
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField } from "@mui/material";
import { ContentContainer, HeaderContainer } from "./styled";
import * as yup from 'yup';
import { useFormik } from "formik";
import { AnalyticParameters } from "../../../../../Models/AnalyticParameters";
import { apiGetAllAnalytics, apiSetAnalytic } from "../../../../../Api/AnalyticsApi";
import { apiGetAllBusiness } from "../../../../../Api/BusinessApi";
import _ from "lodash";
import { Project } from "../../../../../Models/Business";

interface ISetAnalyticModalProps {
    isOpen: boolean
    onClose: () => void
    analytic?: AnalyticParameters
}

const validationSchema = yup.object({
    level: yup.number().nullable().min(1).max(3).required('Выберите уровень аналитикир'),
    name: yup.string().required('Введите название'),
    projectId: yup.string().required('Выберите проект'),
    parent: yup.string(),
})

const SetAnalyticModal = ({ onClose, isOpen, analytic }: ISetAnalyticModalProps) => {
    const [analytics, setAnalytics] = useState<AnalyticParameters[]>([])
    const [projects, setProjects] = useState<Project[]>([])

    useEffect(() => {
        apiGetAllAnalytics()
            .then(res => setAnalytics(res.data ?? []))
            .catch(err => console.log(err))
        apiGetAllBusiness()
            .then(resp => {
                let projects = _.flatMap(resp.data ?? [],
                    x => x.projects.map((proj: Project) => ({ ...proj }))
                )
                setProjects(projects ?? [])
            })
            .catch(err => console.log(err))
    }, [])

    const formik = useFormik({
        initialValues: {
            level: analytic?.level ?? 1,
            name: analytic?.name ?? '',
            parent: analytic?.parentId ?? '',
            projectId: analytic?.projectId ?? '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            apiSetAnalytic(values.name, values.level ?? 1, values.projectId, values.parent, analytic?.id)
                .then(() => handleClose())
                .catch(err => console.log(err))
        },
    });

    const handleClose = () => {
        formik.resetForm()
        onClose()
    }

    const handleChangeProject = (event: SelectChangeEvent) => {
        const value = event.target.value
        formik.setFieldValue('projectId', value)
        formik.setFieldValue('parent', undefined)
        formik.setFieldValue('level', 1)
    };

    const handleChangeParent = (event: SelectChangeEvent) => {
        const value = event.target.value
        formik.setFieldValue('parent', value)
        let parent = analytics.find(x => x.id === value)
        formik.setFieldValue('level', (parent?.level ?? 0) + 1)
    };

    return <Modal
        open={isOpen}
        onClose={handleClose}

    >
        <ContentContainer>
            <HeaderContainer>
                <h4>Новый проект</h4>
                <Button variant="text" onClick={handleClose}>Закрыть</Button>
            </HeaderContainer>

            <FormControl disabled fullWidth error={formik.touched.level && Boolean(formik.errors.level)}>
                <InputLabel htmlFor="level">Уровень параметра</InputLabel>
                <Select
                    variant="outlined"
                    id="level"
                    value={formik.values.level?.toString()}
                    label="Уровень параметра"
                >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                </Select>
                <FormHelperText>{formik.touched.level && formik.errors.level}</FormHelperText>
            </FormControl>

            <FormControl>
                <TextField
                    id="name"
                    label="Название параметра"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
            </FormControl>

            <FormControl fullWidth error={formik.touched.projectId && Boolean(formik.errors.projectId)}>
                <InputLabel htmlFor="projectId">Проект</InputLabel>
                <Select
                    variant="outlined"
                    id="projectId"
                    value={formik.values.projectId}
                    label="Проект"
                    onChange={handleChangeProject}
                >
                    {projects.map(x => <MenuItem key={x.id} value={x.id} >{x.name}</MenuItem>)}
                </Select>
                <FormHelperText>{formik.touched.projectId && formik.errors.projectId}</FormHelperText>
            </FormControl>

            <FormControl fullWidth error={formik.touched.parent && Boolean(formik.errors.parent)}>
                <InputLabel htmlFor="parent">Родитель</InputLabel>
                <Select
                    variant="outlined"
                    id="parent"
                    value={formik.values.parent}
                    label="Родитель"
                    onChange={handleChangeParent}
                >
                    <MenuItem value="" sx={{ height: 24 }}> </MenuItem>
                    {analytics.filter(x => x.id !== analytic?.id && x.level < 3 && x.projectId === formik.values.projectId)
                        .map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
                </Select>
                <FormHelperText>{formik.touched.parent && formik.errors.parent}</FormHelperText>
            </FormControl>
            <Button variant="outlined" onClick={() => formik.handleSubmit()}>Сохранить</Button>
        </ContentContainer>
    </Modal>
}

export default SetAnalyticModal
