import React, {useEffect, useState} from 'react'
import {ContentContainer, HeaderContainer} from "./styled";
import {Button, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Select, TextField} from "@mui/material";
import {User, UserRole} from "../../../../Models/User";
import {apiGetUsers} from "../../../../Api/UserApi";
import * as yup from "yup";
import {useFormik} from "formik";
import DatePicker from 'react-datepicker'
import dayjs from "dayjs";
import {ApiCreateReport} from "../../../../Api/ReportApi";

interface IGetReportModalProps {
  isOpen: boolean
  onClose: () => void
}

const validationSchema = yup.object({
  user: yup.string().required('Выберите пользователя'),
  startDate: yup.date().required('Выберите дату').typeError('Пожалуйста введите дату'),
  endDate: yup.date().required('Выберите дату').typeError('Пожалуйста введите дату'),
})

const GetReportModal = ({isOpen, onClose}: IGetReportModalProps) => {
  const [users, setUsers] = useState<User[]>([])

  const formik = useFormik({
    initialValues: {
      user: '',
      startDate: null,
      endDate: null,
    },
    validationSchema: validationSchema,
    onSubmit: () => handleSend(),
  });

  useEffect(() => {
    apiGetUsers()
      .then(resp => setUsers((resp.data ?? []).filter((x: User) => x.roles.includes(UserRole.Manager) && !x.isDeleted)))
      .catch(err => console.log(err))
  }, [])


  const handleSend = () => {
    const values = formik.values
    if(!!values.startDate && !!values.endDate) {
      console.log('send')
      ApiCreateReport(values.user, values.startDate, values.endDate)
        // .then(() => handleClose())
    }
  }

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    formik.setFieldValue('startDate', start);
    formik.setFieldValue('endDate', end);
  };

  const dateErrorText = formik.touched.startDate && formik.errors.startDate || (formik.touched.endDate && formik.errors.endDate)

  return <Modal
    open={isOpen}
    onClose={handleClose}
  >
    <ContentContainer>
      <HeaderContainer>
        <h4>Сформировать отчет</h4>
        <Button variant="text" onClick={handleClose}>Закрыть</Button>
      </HeaderContainer>

      <FormControl fullWidth error={formik.touched.user && Boolean(formik.errors.user)}>
        <InputLabel htmlFor="user">Пользователь</InputLabel>
        <Select
          variant="outlined"
          id="user"
          value={formik.values.user ?? ''}
          label="Пользователь"
          onChange={e => formik.setFieldValue("user", e.target.value)}
        >
          {users.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
        </Select>
        <FormHelperText>{formik.touched.user && formik.errors.user}</FormHelperText>
      </FormControl>

      <FormControl fullWidth>
        <DatePicker
          onChange={onChange}
          startDate={formik.values.startDate}
          endDate={formik.values.endDate}
          selectsRange
          maxDate={dayjs().toDate()}
          customInput={<TextField
            fullWidth
            label="Дата"
            variant="outlined"
            error={Boolean(dateErrorText)}
            helperText={dateErrorText}
          />}
        />
      </FormControl>

      <Button variant="outlined" onClick={() => formik.handleSubmit()}>Создать</Button>
    </ContentContainer>
  </Modal>
}

export default GetReportModal
