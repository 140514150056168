import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SendMoneyPage from "./Bot/SendMoneyPage";
import ManagerAddExpensesPage from "./Bot/ManagerAddExpensesPage";
import ManagerBalancesListPage from "./Bot/ManagerBalancesListPage";
import ManagerExpensesList from "./Bot/ManagerExpensesListPage";
import LoginPage from "./Admin/LoginPage";
import PrivateRoute from "../Components/PrivateRoute";
import UsersPage from "./Admin/UsersPage";
import AdminLayout, { PageIds } from "../Components/AdminLayout";
import ExpensesPage from "./Admin/ExpensesPage";
import TransfersPage from "./Admin/TransfersPage";
import CatalogPage from "./Admin/CatalogsPage";
import { UserRole } from "../Models/User";
import AdministratorsPage from "./Admin/AdministratosPage";
import SettingsPage from "./Admin/SettingsPage";
import RevenuePage from "./Admin/RevenuePage";
import AddRevenuePage from "./Bot/AddRevenuePage";
import OperationsListPage from "./Bot/OperationsListPage";

const Pages = () => {
  return (
    <Router>
      <Routes>
        <Route path="/bot">
          <Route
            path="PaymasterSendMoney"
            element={<SendMoneyPage senderRole={UserRole.Paymaster} />}
          />
          <Route
            path="PaymasterShowMangerBalancesList"
            element={<ManagerBalancesListPage />}
          />
          <Route
            path="ManagerSendMoney"
            element={<SendMoneyPage senderRole={UserRole.Manager} />}
          />
          <Route
            path="ManagerAddExpenses"
            element={<ManagerAddExpensesPage />}
          />
          <Route path="ManagerExpensesList" element={<ManagerExpensesList />} />
          <Route path="AddRevenue" element={<AddRevenuePage />} />
          <Route path="OperationsList" element={<OperationsListPage />} />
          <Route
            path="edit-expenses/:expensesId"
            element={<ManagerAddExpensesPage />}
          />
          <Route path="edit-revenue/:revenueId" element={<AddRevenuePage />} />
          <Route
            path="edit-transfer/:transferId"
            element={<SendMoneyPage senderRole={UserRole.Manager} />}
          />
        </Route>
        <Route path="/admin">
          <Route path="login" element={<LoginPage />} />
          {PrivateRoute({
            path: "transfers",
            element: (
              <AdminLayout selectedPage={PageIds.transfers}>
                <TransfersPage />
              </AdminLayout>
            ),
          })}
          {PrivateRoute({
            path: "expenses",
            element: (
              <AdminLayout selectedPage={PageIds.expenses}>
                <ExpensesPage />
              </AdminLayout>
            ),
          })}
          {PrivateRoute({
            path: "revenue",
            element: (
              <AdminLayout selectedPage={PageIds.revenue}>
                <RevenuePage />
              </AdminLayout>
            ),
          })}
          {PrivateRoute({
            path: "catalogs",
            element: (
              <AdminLayout selectedPage={PageIds.catalogs}>
                <CatalogPage />
              </AdminLayout>
            ),
          })}
          {PrivateRoute({
            path: "admins",
            element: (
              <AdminLayout selectedPage={PageIds.admins}>
                <AdministratorsPage />
              </AdminLayout>
            ),
          })}
          {PrivateRoute({
            path: "settings",
            element: (
              <AdminLayout selectedPage={PageIds.settings}>
                <SettingsPage />
              </AdminLayout>
            ),
          })}
          {PrivateRoute({
            path: "*",
            element: (
              <AdminLayout selectedPage={PageIds.users}>
                <UsersPage />
              </AdminLayout>
            ),
          })}
          {PrivateRoute({
            index: true,
            element: (
              <AdminLayout selectedPage={PageIds.users}>
                <UsersPage />
              </AdminLayout>
            ),
          })}
        </Route>
        <Route path="/" element={<div></div>} />
      </Routes>
    </Router>
  );
};

export default Pages;
