import styled from '@emotion/styled'

export const TableContainer = styled.div`
  .deleted.MuiTableRow-root {
    opacity: 0.5;
  }
  
  .MuiToolbar-root {
    & > *.MuiBox-root {
      justify-content: flex-start;
    }
  }
  
  .MuiCollapse-wrapper.MuiCollapse-vertical{
    & > *.MuiCollapse-wrapperInner.MuiCollapse-vertical{
      & > *.MuiBox-root{
        display: flex;
        flex-direction: column;
      }
    }
  }
`
