import React, { useEffect } from 'react'
import { Drawer, List, ListItem, ListItemButton, ListItemText, ThemeProvider } from "@mui/material";
import { Container, ContentContainer } from './styled'
import { useNavigate } from "react-router";
import axios from "axios";
import { createTheme } from "@mui/material/styles";
import { ruRU } from '@mui/material/locale';

interface IAdminLayoutProps {
    selectedPage: PageIds
    children?: React.ReactNode
}

interface IMenuItem {
    id: PageIds
    text: string
    link: string
}

export enum PageIds {
    users,
    expenses,
    transfers,
    revenue,
    catalogs,
    admins,
    settings,
}

export const AdminPageList = {
    [PageIds.admins] : {
        id: PageIds.admins,
        text: 'Администраторы',
        link: '/admin/admins',
    },
    [PageIds.users] : {
        id: PageIds.users,
        text: 'Пользователи',
        link: '/admin/users',
    },
    [PageIds.transfers] : {
        id: PageIds.transfers,
        text: 'Трансферы в системе',
        link: '/admin/transfers',
    },
    [PageIds.expenses] : {
        id: PageIds.expenses,
        text: 'Расходы',
        link: '/admin/expenses',
    },
    [PageIds.revenue] : {
        id: PageIds.revenue,
        text: 'Приходы',
        link: '/admin/revenue',
    },
    [PageIds.catalogs] : {
        id: PageIds.catalogs,
        text: 'Справочники',
        link: '/admin/catalogs',
    },
    [PageIds.settings] : {
        id: PageIds.settings,
        text: 'Настройки',
        link: '/admin/settings',
    },
}

const theme = createTheme(
    undefined,
    ruRU
);

const AdminLayout = ({ selectedPage, children }: IAdminLayoutProps) => {
    const navigate = useNavigate()

    const handleNavigate = (item: IMenuItem) => () => {
        navigate(item.link)
    }

    const handleExit = () => {
        localStorage.removeItem('token')
        navigate('/admin/login')
    }
    return <Container>
        <Drawer
            variant="permanent"
            anchor="left"
        >
            <List sx={{ marginTop: '50px', padding: '0 10px' }}>
                {Object.values(AdminPageList).map((item) => (
                    <ListItem key={item.text} disablePadding className={item.id === selectedPage ? 'selected' : ''}>
                        <ListItemButton onClick={handleNavigate(item)}>
                            <ListItemText primary={item.text}/>
                        </ListItemButton>
                    </ListItem>
                ))}

            </List>
            <ListItem sx={{ marginTop: 'auto' }}>
                <ListItemButton onClick={handleExit}>
                    <ListItemText primary="Выход"/>
                </ListItemButton>
            </ListItem>
        </Drawer>
        <ContentContainer>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </ContentContainer>
    </Container>
}

export default AdminLayout
