import React, { useEffect, useState } from 'react'
import { Button, ListItemIcon, MenuItem } from "@mui/material";
import MaterialReactTable from "material-react-table";
import { MRT_Localization } from "material-react-table/src/localization";
import { MRT_ColumnDef, MRT_FilterOption, MRT_TableState } from "material-react-table/dist/MaterialReactTable";
import { Delete, Edit, Restore } from "@mui/icons-material";
import { json2csvAsync } from "json-2-csv";
import { TableContainer } from "./styled";
import { IDelete } from "../../Models/IDelete";

interface ITableProps<TData extends { isDeleted: boolean }> {
    columns: MRT_ColumnDef<TData>[]
    data: TData[]
    csvDataMapper?: (data: TData[]) => any[]
    handleToggleDelete?: (data: TData) => void
    handleEdit?: (data: TData) => void
    initialState?: Partial<MRT_TableState<TData>>;
    state?: Partial<MRT_TableState<TData>>
}

function GetDeleteColumn<TData extends IDelete>(): MRT_ColumnDef<TData> {
    return {
        id: 'isDeleted',
        accessorKey: 'isDeleted',
        header: 'Удален',
        accessorFn: (value: TData) => value.isDeleted ? 'Да' : 'Нет',
        size: 100,
        filterSelectOptions: [
            { text: "Удален", value: "да" },
            { text: "Не удален", value: "Нет" },
        ],
    }
}


function Table<TData extends IDelete>(props: ITableProps<TData>) {
    const [columns, setColumns] = useState<Array<MRT_ColumnDef<TData>>>([])

    useEffect(() => {
        let enrichedColumns: MRT_ColumnDef<TData>[] = props.columns.map(x => {
            if (x.meta === 'data')
                return {
                    ...x,
                    muiTableHeadCellFilterTextFieldProps: {
                        type: 'date',
                    },
                    columnDefType: 'data',
                    sortingFn: 'datetime',
                    enabledColumnFilterOptions: DefaultDateFilters,
                    filterFn: 'equals'
                }
            if (x.meta === 'string')
                return {
                    ...x,
                    enabledColumnFilterOptions: DefaultStringFilters,
                }
            if (x.meta === 'number')
                return {
                    ...x,
                    muiTableHeadCellFilterTextFieldProps: {
                        type: 'number',
                    },
                    enabledColumnFilterOptions: DefaultStringFilters,
                    filterFn: 'equals'
                }
            return x
        })
        let columns = [...enrichedColumns, GetDeleteColumn<TData>()]
        setColumns(columns)
    }, [props.columns])

    const exportToCsv = (data: TData[]) => {
        let formattedData = (props.csvDataMapper && props.csvDataMapper(data)) ?? []

        json2csvAsync(formattedData).then((csv: string) => {
            const url = window.URL.createObjectURL(new Blob([csv]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.csv');
            document.body.appendChild(link);
            link.click();
        }).catch(err => console.log(err))
    }

    const renderToggleDeleteButton = (item: TData) => item.isDeleted ?
        <><ListItemIcon><Restore/></ListItemIcon>Востановить</> :
        <><ListItemIcon><Delete/></ListItemIcon>Удалить</>

    if(columns.length === 0) return <></>

    return <TableContainer>
        <MaterialReactTable
            columns={columns}
            data={props.data}
            enablePagination={false}
            enableRowVirtualization
            enableToolbarBottom={false}
            enableMultiSort
            initialState={props.initialState}
            muiTableBodyRowProps={({ row }) => ({ className: row.original.isDeleted ? 'deleted' : '' })}
            renderToolbarTopCustomActions={({ table }) => {
                if (!props.csvDataMapper) return <></>
                const handleExport = () => {
                    let data = table.getFilteredRowModel().rows.map(x => x.original)
                    exportToCsv(data)
                }

                return (<Button onClick={handleExport}>Экспорт</Button>)
            }}
            localization={RusLocalization}
            enableRowActions={props.handleEdit !== undefined || props.handleToggleDelete !== undefined}
            renderRowActionMenuItems={({ closeMenu, row }) => [
                props.handleEdit &&
                <MenuItem
                    key={0}
                    onClick={() => props.handleEdit && props.handleEdit(row.original)}
                    sx={{ m: 0 }}
                >
                    <ListItemIcon>
                        <Edit/>
                    </ListItemIcon>
                    Редактировать
                </MenuItem>,
                props.handleToggleDelete && <MenuItem
                    key={1}
                    onClick={() => props.handleToggleDelete && props.handleToggleDelete(row.original)}
                    sx={{ m: 0 }}
                >
                    {renderToggleDeleteButton(row.original)}
                </MenuItem>,
            ]}
        />
    </TableContainer>
}

const RusLocalization: Partial<MRT_Localization> = {
    actions: 'Действия',
    cancel: 'Отмена',
    changeFilterMode: 'Изменить режим фильтра',
    clearFilter: 'Очистить фильтр',
    clearSearch: 'Очистить поиск',
    clearSort: 'Очистить сортировку',
    columnActions: 'Действия стобца',
    edit: 'Изменить',
    expand: 'Развернуть',
    expandAll: 'Развернуть все',
    filterByColumn: 'Отфильтровать по {column}',
    filterMode: 'Режим фильтра: {filterType}',
    filterBetween: 'Между',
    filterContains: 'Содержит',
    filterEmpty: 'Пустой',
    filterEndsWith: 'Заканчивается на',
    filterEquals: 'Равен',
    filterFuzzy: 'Примерный',
    filterGreaterThan: 'Большее чем',
    filterLessThan: 'Меньше чем',
    filterNotEmpty: 'Не пустой',
    filterNotEquals: 'Не равен',
    filterStartsWith: 'Начинается с',
    filteringByColumn: 'Отфильтровано по {column}',
    filterBetweenInclusive: 'Между включительно',
    filterGreaterThanOrEqualTo: 'Больше или равно',
    filterLessThanOrEqualTo: 'Меньше или равно',
    hideAll: 'Скрыть все',
    hideColumn: 'Скрыть столбец {column}',
    rowActions: 'Действия строки',
    pinToLeft: 'ЗАкрепить слева',
    pinToRight: 'Закрепить справа',
    save: 'Сохранить',
    search: 'Поиск',
    selectedCountOfRowCountRowsSelected: '{selectedCount} из {rowCount} выбранных строк',
    showAll: 'Показать все',
    showAllColumns: 'Показать все столбцы',
    showHideColumns: 'Показать/скрыть столбцы',
    showHideFilters: 'Показать/скрыть фильтры',
    showHideSearch: 'Показать/скрыть поиск',
    sortByColumnAsc: 'Сортировать {column} по возрастанию',
    sortByColumnDesc: 'Сортировать {column} по убыванию',
    thenBy: ', потом по ',
    toggleDensity: 'Растояние между строк',
    toggleFullScreen: 'Полноэкранный режим',
    toggleSelectAll: 'Выбрать все',
    toggleSelectRow: 'Выбрать строку',
    unpin: 'Открепить',
    unsorted: 'Несортированный',
}

export const DefaultStringFilters: MRT_FilterOption[] = ['fuzzy', 'contains', 'startsWith', 'endsWith', "empty", "notEmpty", "equals"]
export const DefaultNumberFilters: MRT_FilterOption[] = ['fuzzy', 'equals', 'lessThan', 'greaterThan', 'between',]
export const DefaultDateFilters: MRT_FilterOption[] = ['equals', 'lessThan', 'greaterThan', 'between', "greaterThanOrEqualTo", "lessThanOrEqualTo"]


export default Table
