import { User, UserRole } from "./User";
import { IDelete } from "./IDelete";
import { Business, Project } from "./Business";

export interface Transfer extends IDelete {
    id: string
    transferDate: Date
    sender: User
    recipient: User
    receivingDate: Date
    amount: number
    comment: string
    status: TransferStatus
    senderRole: UserRole
    recipientRole: UserRole
    senderProject: Project
    recipientProject: Project
}

export enum TransferStatus {
    Created = 'Created',
    Confirmed = 'Confirmed',
    Rejected = 'Rejected',
}

export const TransferStatusRus = {
    [TransferStatus.Created]: 'Создан',
    [TransferStatus.Confirmed]: 'Подтверждён',
    [TransferStatus.Rejected]: 'Отклонён',
}
