import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  & > .MuiButton-root {
    margin-bottom: 30px;
  }
`
