import { apiGetSettings } from "../Api/SettiingsApi";

class ConstantHelper {
  public MaxDayBefore: Date | undefined = undefined;
  public MaxDayAfter: Date | undefined = undefined;
  public Formatter = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
    maximumFractionDigits: 0,
  });
  constructor() {}
}

const instance = new ConstantHelper();

export const InitializeConstants = async () => {
  apiGetSettings().then((resp) => {
    instance.MaxDayBefore = new Date();
    instance.MaxDayBefore.setDate(
      new Date().getDate() - resp.data.maxDayBefore
    );

    instance.MaxDayAfter = new Date();
    instance.MaxDayAfter.setDate(new Date().getDate() + resp.data.maxDayAfter);

    console.log(instance);
  });
};

export default instance;

// export const MaxDayBefore = instance.maxDayBefore
// export const MaxDayAfter = instance.maxDayAfter
//
// export const MoneyFormatter = instance.formatter
