import axios from 'axios'
import _ from "lodash";
import { Project, ProjectWithBusiness } from "../Models/Business";

export const apiGetAllBusiness = () => axios.get('/api/business/all')

export const apiGetAllProjectWithBusiness = (): Promise<ProjectWithBusiness[]> => axios
    .get('/api/business/all')
    .then(resp => _.flatMap(resp.data ?? [], x => x.projects.map((proj: Project) => ({ ...proj, business: x }))))

export const apiSetBusiness = (name: string, id?: string) => axios.post(`/api/business/set`, { id, name })

export const apiToggleDeleteBusiness = (id: string) => axios.post(`/api/business/${id}/toggleIsDelete`)

export const apiSetProject = (name: string, businessId: string, id?: string) => axios.post(`/api/business/project/set`, { id, name, businessId })

export const apiToggleDeleteProject = (id: string) => axios.post(`/api/business/project/${id}/toggleIsDelete`)
