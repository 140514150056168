import React, { useEffect, useState } from 'react'
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField } from "@mui/material";
import { ContentContainer, HeaderContainer } from "./styled";
import * as yup from 'yup';
import { useFormik } from "formik";
import { Business, Project } from "../../../../../Models/Business";
import { apiGetAllBusiness, apiSetProject } from "../../../../../Api/BusinessApi";

interface ISetProjectModalProps {
    isOpen: boolean
    onClose: () => void
    project?: Project
}

const validationSchema = yup.object({
    name: yup.string().required('Введите название'),
    business: yup.string().required('Выберите бизнес'),
})

const SetProjectModal = ({ onClose, isOpen, project }: ISetProjectModalProps) => {
    const [business, setBusiness] = useState<Business[]>([])

    useEffect(() => {
        apiGetAllBusiness()
            .then(resp => setBusiness(resp.data ?? []))
            .catch(err => console.log(err))
    }, [])

    const formik = useFormik({
        initialValues: {
            name: project?.name ?? '',
            business: project?.businessId ?? '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            apiSetProject(values.name, values.business, project?.id).then(handleClose).catch(err => console.log(err))
        },
    });

    const handleClose = () => {
        formik.resetForm()
        onClose()
    }

    const handleChangeSelect = (field: string) => (event: SelectChangeEvent) => {
        const value = event.target.value
        formik.setFieldValue(field, value)
    };

    return <Modal
        open={isOpen}
        onClose={handleClose}
    >
        <ContentContainer>
            <HeaderContainer>
                <h4>Новый проект</h4>
                <Button variant="text" onClick={handleClose}>Закрыть</Button>
            </HeaderContainer>

            <FormControl>
                <TextField
                    id="name"
                    label="Название проекта"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
            </FormControl>

            <FormControl fullWidth error={formik.touched.business && Boolean(formik.errors.business)}>
                <InputLabel htmlFor="business">Бизнес</InputLabel>
                <Select
                    variant="outlined"
                    id="business"
                    value={formik.values.business}
                    label="Бизнес"
                    onChange={handleChangeSelect('business')}
                >
                    {business.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
                </Select>
                <FormHelperText>{formik.touched.business && formik.errors.business}</FormHelperText>
            </FormControl>
            <Button variant="outlined" onClick={() => formik.handleSubmit()}>Сохранить</Button>
        </ContentContainer>
    </Modal>
}

export default SetProjectModal
