import React, { useEffect, useMemo, useState } from 'react'
import { Container } from "./styled";
import { Button } from "@mui/material";
import { Expenses } from "../../../Models/Expenses";
import { AnalyticParameters } from "../../../Models/AnalyticParameters";
import { apiGetAllExpenses, apiToggleDeleteExpenses } from "../../../Api/ExpensesApi";
import { apiGetAllAnalytics } from "../../../Api/AnalyticsApi";
import { CellExpand } from "../../../Components/CellExpand";
import SetExpensesModal from "./SetExpenses";
import Table, { DefaultDateFilters, DefaultNumberFilters, DefaultStringFilters } from "../../../Components/Table";
import { MRT_ColumnDef } from "material-react-table/dist/MaterialReactTable";
import ConstantHelper from "../../../Models/Constants";

interface ModalState {
    open: boolean
    editModel?: Expenses
}

const ExpensesPage = () => {
    const [expenses, setExpenses] = useState<Expenses[]>([])
    const [analytics, setAnalytics] = useState<AnalyticParameters[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [modalState, setModalState] = useState<ModalState>({ open: false })

    useEffect(() => loadData(), [])
    const loadData = () => {
        Promise.all([
            apiGetAllExpenses()
                .then(resp => setExpenses(resp.data ?? []))
                .catch(err => console.log(err)),
            apiGetAllAnalytics()
                .then(res => setAnalytics(res.data ?? []))
                .catch(err => console.log(err))
        ]).finally(() => setIsLoading(false))
    }

    const columns = useMemo<MRT_ColumnDef<Expenses>[]>(() => [
        {
            id: 'transferDate',
            accessorKey: 'transferDate',
            header: 'Дата ввода',
            accessorFn: (row) => new Date(row.transferDate),
            Cell: ({ cell }) => cell.getValue<Date>().toLocaleString(),
            size: 150,
            meta: 'data',
        },
        {
            id: 'manager',
            accessorKey: 'manager',
            header: 'Распорядитель',
            accessorFn: (value: Expenses) => value.manager.name,
            width: 200,
            meta: 'string',
        },
        {
            id: 'business',
            accessorKey: 'business',
            header: 'Бизнес',
            accessorFn: (value: Expenses) => value.business.name,
            width: 200,
            meta: 'string',
        },
        {
            id: 'project',
            accessorKey: 'project',
            header: 'Проект',
            accessorFn: (value: Expenses) => value.project.name,
            width: 200,
            meta: 'string',
        },
        {
            id: 'operationDate',
            accessorKey: 'operationDate',
            header: 'Дата расхода',
            accessorFn: (row) => new Date(row.operationDate),
            Cell: ({ cell }) => cell.getValue<Date>().toLocaleDateString(),
            size: 150,
            meta: 'data',
        },
        {
            id: 'amount',
            accessorKey: 'amount',
            header: 'Сумма',
            Cell: ({ cell }) => ConstantHelper.Formatter.format(cell.getValue<number>()),
            size: 150,
            meta: 'number',
        },
        {
            id: 'analytic1',
            header: 'Аналитика 1',
            accessorFn: (value: Expenses) => getAnalyticName(value.analyticIds[0]),
            meta: 'string',
        },
        {
            id: 'analytic2',
            header: 'Аналитика 2',
            accessorFn: (value: Expenses) => getAnalyticName(value.analyticIds[1]),
            meta: 'string',
        },
        {
            id: 'analytic3',
            header: 'Аналитика 3',
            accessorFn: (value: Expenses) => getAnalyticName(value.analyticIds[2]),
            meta: 'string',
        },
        {
            id: 'comment',
            accessorKey: 'comment',
            header: 'Комментарий',
            Cell: ({ cell, table }) => <CellExpand value={cell.getValue()} width={200}/>,
            size: 200,
            meta: 'string',
        },
    ], [analytics])

    const handleEditCell = (item: Expenses) => {
        setModalState({ open: true, editModel: item })
    }

    const handleToggleDeleteItem = (item: Expenses) => {
        apiToggleDeleteExpenses(item.id).then(() => loadData()).catch(err => console.log(err))
    }

    const formatDataToCsv = (data: Expenses[]) => {
        return data.map(x => ({
            id: x.id,
            transferDate: new Date(x.transferDate).toLocaleDateString(),
            operationDate: x.operationDate ? new Date(x.operationDate).toLocaleDateString() : '',
            manager: x.manager.name,
            amount: x.amount,
            business: x.business.name,
            project: x.project.name,
            analytic1: getAnalyticName(x.analyticIds[0]),
            analytic2: getAnalyticName(x.analyticIds[1]),
            analytic3: getAnalyticName(x.analyticIds[2]),
            comment: x.comment,
            isDeleted: x.isDeleted ? 'Да' : 'Нет',
        }))
    }

    const getAnalyticName = (analyticId: string) => analytics.find(x => x.id === analyticId)?.name

    const closeModal = () => {
        setModalState({ open: false })
        loadData()
    }

    if(isLoading) return <div>Loading...</div>

    return <Container>
        <Button
            variant="outlined"
            sx={{ width: '250px' }}
            onClick={() => setModalState({ open: true })}
        >
            Создать расход
        </Button>
        <Table
            columns={columns}
            data={expenses}
            csvDataMapper={formatDataToCsv}
            handleEdit={handleEditCell}
            handleToggleDelete={handleToggleDeleteItem}
            initialState={{ sorting: [{ id: 'transferDate', desc: true }] }}
        />

        {modalState.open && <SetExpensesModal isOpen={modalState.open} expenses={modalState.editModel} onClose={closeModal}/>}
    </Container>
}

export default ExpensesPage
