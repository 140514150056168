import styled from '@emotion/styled'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 30px 20px;

  min-width: 600px;
  min-height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  
  & > * {
    margin-top: 20px !important;
  }
  
  & > button {
    margin-left: auto;
    width: 200px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; 
  
  & > * {
    min-width: 200px;
    //& > *:nth-child(n+1){
    //  margin-top: 20px;
    //}
  }
  & > *:nth-child(2){
    margin-left: 40px;
  }
`
