import styled from '@emotion/styled'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 30px 20px;

  min-width: 400px;
  min-height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  
  & > * {
    margin-top: 20px !important;
  }
  
  & > button {
    margin-left: auto;
    width: 200px; 
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
